<template>
  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
    <v-card class="pb-3">
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        <v-text-field v-model.lazy="clm[fields[0].value]" :key="increasingNumber" reverse
                      :label="fields[0].name"
                      v-money="money" @input="emit" class="mb-8"></v-text-field>
        <v-text-field v-model.lazy="clm[fields[1].value]" reverse :label="fields[1].name"
                      v-money="money" @input="emit" class="mb-8"></v-text-field>
        <v-text-field v-model.lazy="clm[fields[2].value]" reverse :label="fields[2].name"
                      v-money="money" @input="emit"
                      class="mb-8" v-if="fields[2].value"></v-text-field>
        <v-text-field v-if="fields[2].value === ''" style="margin-bottom: 118px"
                      label="Remaining Reserve"
                      v-model="remaining" reverse readonly></v-text-field>
        <v-text-field v-else style="margin-bottom: 20px" label="Remaining Reserve"
                      v-model="remaining" reverse readonly></v-text-field>
<!--        :label="fields[2].name"-->
<!--        :label="fields[3].name"-->
      </v-card-text>
    </v-card>
  </v-col>

</template>

<script>
  import {VMoney} from 'v-money'

  export default {
    name: "AmountCard",
    props: {
      clmProp: Object,
      title: String,
      fields: Array,
      default() {
        return {};
      }
    },
    directives: {money: VMoney},
    data() {
      return {
        money: {
          decimal: '.',
          thousands: ',',
          prefix: '$',
          suffix: '',
          precision: 2,
        },
        clm: this.clmProp,
        changed: false,
        increasingNumber: 1,
      };
    },

    computed: {
      remaining() {
        return this.sub2(this.clm[this.fields[0].value], this.clm[this.fields[1].value],
          this.clm[this.fields[2].value]);
      },
    },

    methods: {
      zero() {
        let newValue = parseFloat(this.clm[this.fields[1].value].replace(/[$|,]/g,''))
          + parseFloat(this.clm[this.fields[2].value].replace(/[$|,]/g,''))
        this.clm[this.fields[0].value] ='$' + (newValue).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
        this.increasingNumber += 1; // key to update field
        this.$emit('input', this.clm);
      },

      emit (value) {
        this.$emit('input', this.clm);
      },

      sub2 (a,b,c) {
        let A = '';
        let B = '';
        let C = '';
        if (a) { A = a.replace(/[$|,]/g,'');}
        if (b) { B = b.replace(/[$|,]/g,'');}
        if (c) { C = c.replace(/[$|,]/g,'');}
        let diff = 0;
        Math.round((A-B-C)*100)<0 ? diff = '--' : Math.round((A-B-C)*100) === 0 ? diff = "$0.00"
          : diff = '$' + (A-B-C).toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return diff;
      },
    }

}
</script>
<style>
</style>

